



















import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import HseRiskTemplate from "@/shared/modules/hse-risk-templates/hse-risk-templates.model";
import { getNodeApiUrlByVersion } from "@/config";
import RiskAssessmentTemplateNameHeader
  from "@/company/components/risk-assessments/RiskAssessmentTemplateNameHeader.vue";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import HseRiskTemplateService from "@/shared/modules/hse-risk-templates/hse-risk-templates.service";
import { CompanyRoutes } from "@/shared/router/company";
import RiskCategorySelect from "@/company/components/risk-assessments/RiskCategorySelect.vue";
import SelectAction from "@/shared/classes/components/data-table/data-table-select-actions";
import RiskTemplateItemExpanded from "@/company/components/risk-assessments/RiskTemplateItemExpanded.vue";
import SelectedRiskAssign from "@/company/components/risk-assessments/SelectedRiskAssign.vue";
import _ from "lodash"
import baseHttp from "@/shared/http";
import RoleCan from "@/shared/components/RoleCan.vue";
import { RoleLevel } from "@/shared/modules/role/role.model";
import roleCan from "@/shared/helpers/role-can.helper";
import FormBase from "@/shared/classes/form/form-base";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import Field from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import { Positions } from "@/shared/interfaces/classes/form.interfaces";
import { validateFields } from "@/shared/helpers/validate";

@Component({
  methods: { __ },
  components: { DataTable, RiskCategorySelect, RoleCan },
})
export default class TemplatesRiskAssessments extends Vue {
  table: DataTableBase | null = null
  selectedItems: HseRiskTemplate[] = []
  http = baseHttp
  RoleLevel = RoleLevel
  selectActionForm: FormBase | null = null
  forms: { [key: string]: FormBase } = {}

  created() {
    this.selectActionForm = new FormBase()
      .setInline(true)
      .setFields([
        new SearchableField()
          .setKey('responsibleUserUuid')
          .setDense(true)
          .isRequired()
          .loadItems({
            endpoint: `company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20,
          })
          .setTitle(__('company.components.risk-assessments.selected-assign.form.responsible')),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('assessmentDate')
          .setTitle(__('company.components.risk-assessments.selected-assign.form.assessment-date'))
          .setDense(true)
          .setMeta({ class: 'ml-2' })
          .isRequired(),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('barrierDeadline')
          .setTitle(__('company.components.risk-assessments.selected-assign.form.barrier-deadline'))
          .setDense(true)
          .setMeta({ class: 'ml-2' })
          .isRequired(),
        new SelectField()
          .setKey("status")
          .setDense(true)
          .setItems([
            new SelectItem()
              .setValue(1)
              .setTitle(__('company.components.risk-assessments.selected-assign.form.barrier-status-options.in-progress')),
            new SelectItem()
              .setValue(2)
              .setTitle(__('company.components.risk-assessments.selected-assign.form.barrier-status-options.completed')),
          ])
          .setTitle(__('company.components.risk-assessments.selected-assign.form.barrier-status'))
          .setMeta({ class: 'ml-2' })
      ])
      .setBeforeSubmit(this.beforeSubmit)
      .setOnSuccess(this.onSelectActionSubmit)
      .setInitialValues({
        assessmentDate: new Date(),
        status: 1
      })
      .setValidate((data: any) => {
        let valid = true;

        const errors = validateFields(data, this.selectActionForm?.fields);
        if (Object.keys(errors).length) {
          this.selectActionForm?.setErrors(errors);
          valid = false;
        }

        Object.values(this.forms).forEach(form => {
          const errors = validateFields(form.generateDataForValidation(), form.fields, { onlyRegionLangRequired: true });
          if (Object.keys(errors).length) {
            form?.setErrors(errors);
            valid = false;
          }
        })

        if (!valid) {
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.error,
            message: __("validation.fill-all-field"),
          });
        }

        return valid;
      })
      .setSubmit({
        color: 'primary',
        position: Positions.right,
        class: 'ml-2',
        text: __('general.assign'),
      })

    this.table = new DataTableBase()
      .setModel(HseRiskTemplate)
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-risks/templates`)
      .setGroupBy('hseRiskCategoryName')
      .setGroupDesc(false)
      .setPaginationNotVisible(true)
      .setMultipleCheckbox(roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]))
      .setDoubleClick(this.navigateToEdit)
      .setGroupSelect(true)
      .setMaxSelectAmount(50)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setValueComponent(RiskAssessmentTemplateNameHeader)
          .setText(__('company.views.risk-assessments.templates.table.columns.name')),
      ])
      .setExpandedItemComponent(RiskTemplateItemExpanded, {
        onUpdate: (item: HseRiskTemplate) => {
          const index = this.selectedItems.findIndex(el => el.uuid === item.uuid);
          if (index === -1) this.selectedItems.push(item);
          else this.selectedItems[index] = item;
        },
        onDestroy: (item: HseRiskTemplate) => {
          this.selectedItems = this.selectedItems.filter(el => el.uuid !== item.uuid);
          delete this.forms[item.uuid];
        },
        onFormSetup: (uuid: string, form: FormBase) => {
          this.forms[uuid] = form
        }
      })
      .setSelectActions([
        new SelectAction()
          .setComponent(SelectedRiskAssign)
          .setEnabled(true)
          .setMeta({ form: this.selectActionForm })
      ])
      .setActions([
        new EditAction()
          .setAllowedForItem((item: HseRiskTemplate) => !item.selected)
          .setAction(this.navigateToEdit),
        new DeleteAction()
          .setAllowedForItem((item: HseRiskTemplate) => item.isCompanyTemplate && !item.selected)
          .setAction(this.delete)
      ])

    this.table.classes.tableCard = 'elevation-0'
    this.table.noPadding = true
  }

  onSelectActionSubmit(data: any) {
    const dataToSubmit = this.selectedItems.map(item => {
      return {
        ...(_.omit(item, ['uuid'])),
        barriers: item.barriers.map((barrier: any) => ({
          ...barrier,
          date: barrier.date || data.barrierDeadline
        })),
        responsibleUserUuid: data.responsibleUserUuid,
        assessmentDate: data.assessmentDate,
        status: data.status
      }
    });

    this.http.post(`${getNodeApiUrlByVersion()}/assigned-hse-risks`, dataToSubmit)
      .then(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __("company.views.risk-assessments.templates.table.actions.assign.on-success"),
        });
        this.tableRef?.refresh();
      })
      .catch()
  }

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.riskAssessmentsCreate })
  }

  navigateToEdit(item: HseRiskTemplate): void {
    this.$router.push({ name: CompanyRoutes.riskAssessmentsEdit, params: { uuid: item.uuid } })
  }

  delete(hseRiskTemplate: HseRiskTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.risk-assessments.templates.table.actions.delete.title'),
        text: __('company.views.risk-assessments.templates.table.actions.delete.text', { name: hseRiskTemplate.name }),
        onYes: () => HseRiskTemplateService.destroyCompany(hseRiskTemplate.uuid)
          .then(() => {
            this.table?.removeItem(hseRiskTemplate)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.risk-assessments.templates.table.actions.delete.on-success')
            })
          })
      }
    })
  }

  beforeSubmit(): Promise<void> {
    return new Promise((resolve) => {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: __('company.components.company-instruction-templates.assign.confirm-title'),
          text: __('company.components.company-instruction-templates.assign.confirm-text'),
          onYes: () => resolve(),
        },
      })
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get tableRef(): any {
    return this.$refs.table
  }
}











import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import Form from '@/shared/components/form/Form.vue'
import { FieldTypes } from '@/shared/components/form/field-types'
import HseRiskTemplate from "@/shared/modules/hse-risk-templates/hse-risk-templates.model";
import { getLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import localeHelper from "@/shared/helpers/locale.helper";
import __ from "@/shared/helpers/__";
import ArrayField from "@/shared/classes/form/fields/array-field";
import RiskTemplateForm from "@/admin/components/risk-templates/RiskTemplateForm.vue";
import IResponse from "@/shared/interfaces/modules/response.interface";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import baseHttp from "@/shared/http";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import AssignedHseRisks from "@/shared/modules/assigned-hse-risks/assigned-hse-risks.model";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import { getVersion } from "@/shared/helpers/version.helper";

@Component({
  methods: { __ },
  components: { RiskTemplateForm, Form }
})
export default class RiskTemplateItemExpanded extends Vue {
  @Prop() item!: HseRiskTemplate
  @Prop() meta!: {
    onUpdate: (item: HseRiskTemplate) => void,
    onDestroy: (item: HseRiskTemplate) => void,
    onFormSetup: (uuid: string, form: FormBase) => void,
  }
  http = baseHttp

  form: FormBase | null = null;

  created() {
    const setupForm = (item: HseRiskTemplate) => {
      const valuesToInject: Partial<AssignedHseRisks> = {
        uuid: item.uuid,
        hseRiskCategoryUuid: item.hseRiskCategoryUuid,
        companyUuid: this.company.uuid
      };
      if (item.isCompanyTemplate) valuesToInject.companyHseRiskTemplateUuid = item.uuid;
      else valuesToInject.hseRiskTemplateUuid = item.uuid;

      this.form = new FormBase()
        .setModel(AssignedHseRisks)
        .setEntry(item)
        .setTranslatable(true)
        .setCurrentLanguage(getLanguagesByVersion([localeHelper.getLocale()])[0])
        .setFields([
          new Field()
            .setType(FieldTypes.textArea)
            .setKey(`name.${localeHelper.getLocale()}`)
            .setTitle(__("admin.components.risk-templates.form.risk-title"))
            .setSize(FieldSizes.half)
            .setRows(1)
            .setRequired(localeHelper.getLocale() === getVersion())
            .setOnChange(() => this.formRef.submit()),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey("name")
            .setTranslatable(true)
            .setTitle(__("admin.components.risk-templates.form.risk-title"))
            .setSize(FieldSizes.half)
            .setRows(1)
            .setRequired(localeHelper.getLocale() !== getVersion())
            .setOnChange(() => this.formRef.submit()),
          new ArrayField()
            .setKey("barriers")
            .setDense(true)
            .setHideDetails(true)
            .setDeleteButtonProps({ color: "transparent", elevation: 0, class: 'mt-5 mr-1', placement: 'start' })
            .setAddButtonProps({ isIcon: true, class: 'justify-end expended-add-button' })
            .setJustifyChildren('justify-space-between')
            .setChildren([
              new Field()
                .setType(FieldTypes.textArea)
                .setKey(`title.${localeHelper.getLocale()}`)
                .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
                .setSize(FieldSizes.fiveTwelfth)
                .setRows(1)
                .setRequired(localeHelper.getLocale() === getVersion())
                .setOnChange(() => this.formRef.submit()),
              new Field()
                .setType(FieldTypes.textArea)
                .setKey("title")
                .setTranslatable(true)
                .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
                .setSize(FieldSizes.fiveTwelfth)
                .setRows(1)
                .setRequired(localeHelper.getLocale() !== getVersion())
                .setOnChange(() => this.formRef.submit()),
              new Field()
                .setType(FieldTypes.datePicker)
                .setKey("date")
                .setSize(FieldSizes.twoTwelfth)
                .setMeta({ class: 'align-self-center' })
                .setOnChange(() => this.formRef.submit())
            ]),
          new SelectField()
            .setKey("probability")
            .setDense(true)
            .isRequired()
            .setItems([
              new SelectItem()
                .setValue(5)
                .setTitle(__("admin.components.risk-templates.form.probability-options.especially-likely")),
              new SelectItem()
                .setValue(4)
                .setTitle(__("admin.components.risk-templates.form.probability-options.very-likely")),
              new SelectItem()
                .setValue(3)
                .setTitle(__("admin.components.risk-templates.form.probability-options.likely")),
              new SelectItem()
                .setValue(2)
                .setTitle(__("admin.components.risk-templates.form.probability-options.unlikely")),
              new SelectItem()
                .setValue(1)
                .setTitle(__("admin.components.risk-templates.form.probability-options.very-unlikely")),
            ])
            .setTitle(__("admin.components.risk-templates.form.probability"))
            .setSize(FieldSizes.fiveTwelfth)
            .setOnChange(() => this.formRef.submit()),
          new SelectField()
            .setKey("consequences")
            .setDense(true)
            .isRequired()
            .setItems([
              new SelectItem()
                .setValue(5)
                .setTitle(__("admin.components.risk-templates.form.consequences-options.catastrophic")),
              new SelectItem()
                .setValue(4)
                .setTitle(__("admin.components.risk-templates.form.consequences-options.critical")),
              new SelectItem()
                .setValue(3)
                .setTitle(__("admin.components.risk-templates.form.consequences-options.severe")),
              new SelectItem()
                .setValue(2)
                .setTitle(__("admin.components.risk-templates.form.consequences-options.less-serious")),
              new SelectItem()
                .setValue(1)
                .setTitle(__("admin.components.risk-templates.form.consequences-options.low-significance")),
            ])
            .setTitle(__("admin.components.risk-templates.form.consequences"))
            .setSize(FieldSizes.fiveTwelfth)
            .setMeta({ class: 'ml-4' })
            .setOnChange(() => this.formRef.submit())
        ])
        .setSubmit(false)
        .setInjectValues(valuesToInject)
        .setChangeDataBeforeSubmit(data => {
          return {
            ...data,
            barriers: data.barriers.map((el: any) => {
              return Object.keys(el).reduce((s, c) => {
                if (c.includes(".")) return s;
                return { ...s, [c]: el[c] }
              }, {})
            })
          }
        })
        .setOnSuccess(data => this.meta.onUpdate(data))

      if (this.meta.onFormSetup) this.meta.onFormSetup(item.uuid, this.form);
    }

    this.http.get(`${getNodeApiUrlByVersion()}/company-hse-risk-templates/${this.item.uuid}?hasTranslations=true`)
      .then((response: IResponse<IModelResponse>) => setupForm({ ...response.data, isCompanyTemplate: true }))
      .catch(() => {})

    this.http.get(`${getNodeApiUrlByVersion()}/hse-risk-templates/${this.item.uuid}?hasTranslations=true`)
      .then((response: IResponse<IModelResponse>) => setupForm(response.data))
      .catch(() => {})
  }

  destroyed() {
    this.meta.onDestroy(this.item)
  }

  get formRef(): any {
    return this.$refs.form
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}

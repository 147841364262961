



import { Component, Prop, Vue } from "vue-property-decorator";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import Model from "@/shared/classes/model";
import __ from "@/shared/helpers/__";
import { GlobalActions } from "@/shared/store/global/global.actions";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import FormBase from "@/shared/classes/form/form-base";
import Form from "@/shared/components/form/Form.vue";
import { Positions } from "@/shared/interfaces/classes/form.interfaces";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import Field from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";

@Component({
  components: { Form },
  methods: { __ },
})
export default class SelectedRiskAssign extends Vue {
  @Prop() meta!: any

  destroyed() {
    this.meta?.form.setErrors({})
  }
}
